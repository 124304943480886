.location {
    display: flex;
    align-items: center;
    &__round {
        @include flex-center;
        width: 40px;
        height: 40px;
        margin-right: 16px;
        border-radius: 50%;
        background: $green; }
    &__mark {
        width: 16px;
        height: 18.67px;
        fill: #FEE9E8; }
    &__city {
        @include flex-center;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px; }
    &__metro {
        @include flex-center;
        &::before {
            content: url("data:image/svg+xml,%3Csvg width='9' height='9' viewBox='0 0 9 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 5.97092V5.1664H0.599334L2.71698 0L4.50499 3.01454L6.26304 0L8.36071 5.1664H9V5.97092H5.81354V5.1664H6.29301L5.81354 3.88691L4.50499 6L3.15649 3.88691L2.697 5.1664H3.17647V5.97092H0Z' fill='%23FEE9E8'/%3E%3C/svg%3E%0A");
            @include flex-center;
            width: 15px;
            height: 15px;
            margin: 0 5px;
            background: $green;
            border-radius: 50%; } } }



