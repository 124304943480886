$gap: 30px;

.salon {
   padding-top: 160px;
   &__title {
      margin-bottom: 82px; }
   &__slider {
      .slider__item {
         width: 730px;
         &:not(:last-child) {
            padding-right: $gap;
            .slider__info {
               right: $gap; } } }
      .slider__image {
         height: 500px; }
      .slider__progress {
         margin-top: 82px; } } }

@media ( max-width: 1439px ) {
   .salon {
      padding-top: 80px;
      &__title {
         line-height: 34px;
         font-size: 26px; }
      &__slider .slider__image {
         height: 414px; }
      &__slider .slider__item {
         width: 603px; } }
   .slider {
      &__info {
         width: 366px;
         height: 206px;
         padding: 42px 52px 33px 64px;
         line-height: 143%;
         font-size: 15px; }
      &__title {
         margin-bottom: 15px; } } }
@media ( max-width: 970px ) {
   .salon {
      padding-top: 74px;
      &__title {
         margin-bottom: 72px; }
      &__slider .slider__image {
         width: 568px;
         height: 390px; }
      &__slider .slider__item {
         width: 568px;
         height: 390px;
         &:not(:last-child) {
            padding-right: 22px; } }
      &__slider .slider__item:not(:last-child) .slider__info {
         right: 22px; } }
   .slider {
      &__info {
         width: 334px;
         height: 206px;
         padding: 42px 48px 33px 36px; } } }
@media ( max-width: 767px ) {
   .salon {
      padding-top: 0;
      &__title {
         margin-bottom: 66px;
         font-size: 24px;
         line-height: 31px; }
      &__slider .slider__image {
         width: 335px;
         height: 227px; }
      &__slider .slider__item {
         width: 335px;
         height: 227px;
         &:not(:last-child) {
            padding-right: 10px; } }
      &__slider .slider__item:not(:last-child) .slider__info {
         right: calc(50% + 5px);
         transform: translateX(50%); } }
   .slider {
      &__info {
         bottom: -110px;
         width: 295px;
         height: 151px;
         padding: 20px;
         font-size: 13px;
         line-height: 133%; }
      &__image {
         &_1 {
            background-image: url('../img/21-2.png') !important; } } } }
@media ( max-width: 370px ) {
   .salon {
      &__slider .slider__image {
         width: 300px;
         height: 192px; }
      &__slider .slider__item {
         width: 300px;
         height: 192px; } }
   .slider {
      &__info {
         bottom: -110px;
         width: 260px;
         height: 135px; } } }
