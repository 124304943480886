.service-item {
   display: flex;
   align-items: center;
   justify-content: space-between;
   // height: 400px
   &_big {
      height: 500px;
      width: 100%;
      .service-item__title {
         font-size: 30px;
         line-height: 39px;
         letter-spacing: 0.02em; }
      .service-item__subtitle {
         max-width: 269px;
         margin-bottom: 26px; }
      .service-item__content {
         margin-left: 60%;
         padding-right: 20px; } }
   &_small {
      width: calc(50% - 15px);
      background-size: 50% !important;
      background-position: bottom left !important;
      .service-item__title {
         font-size: 18px;
         line-height: 143%; }
      .service-item__subtitle {
         max-width: 233px;
         margin-bottom: 16px; }
      .service-item__content {
         margin-left: 52%;
         padding-top: 92px;
         padding-bottom: 83px;
         box-sizing: border-box; } }

   &__title {
      display: block;
      margin-bottom: 10px;
      font-weight: bold;
      &_big {
         font-family: $secondary-font; } }
   &__subtitle {
      display: block;
      font-size: 17px;
      line-height: 168%; }

   &__timeblock {
      display: flex;
      align-items: center;
      margin-bottom: 26px; }
   &__icon {
      &_time {
         width: 13px;
         height: 13px;
         margin-right: 10px; } }
   &__time {
      font-size: 17px;
      line-height: 168%; }
   &__price {
      display: block;
      font-weight: bold;
      font-size: 24px;
      line-height: 31px; } }
