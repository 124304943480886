.footer {
   display: flex;
   flex-direction: column;
   // align-items: flex-start
   // height: 100vh
   padding-top: 87px;
   .wrapper {
      // width: 100%
 }      // box-sizing: border-box
   &__map-info {
      position: relative; }
   &__info {
      position: absolute;
      bottom: 0; }
   &__line {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      // max-height: 151px

      padding-top: 44px;
      padding-bottom: 35px; }
   &__text {
      display: block;
      flex-basis: 33%;
      max-width: 372px;
      font-size: 14px;
      line-height: 168%;
      opacity: 0.4;
      &_policy {
         text-align: center;
         &-mobile {
            display: none; } } } }
#map {
   width: 100%;
   height: 100vh;
   flex-grow: 1; }

@media ( max-width: 1200px ) {
   .footer {
      padding-top: 114px;
      &__line {
         align-items: stretch;
         max-height: 167px;
         padding-top: 54px;
         padding-bottom: 41px; }
      &__text {
         text-align: right;
         &_policy {
            margin-left: auto;
            align-self: flex-start;
            // margin-right: -308px
            text-align: right;
            &-mobile {
               display: block;
               flex-grow: 1; }
            &-desktop {
               display: none; } }
         &_oferta {
            text-align: left; } }
      &__copyright {
         display: flex;
         flex-direction: column; } }
   .map-info {
      width: 444px;
      max-width: 100%;
      padding: 55px 0 56px 0;
      &__wrapper {
         margin-left: 72px; }
      &__img {
         width: 180px;
         height: 143px;
         margin-bottom: 34px; }
      &__text, &__block, &__phone {
         font-size: 15px; }
      &__social {
         margin-top: 45px; } } }

@media ( max-width: 970px ) {
   .footer {
      &__line {
         flex-direction: column;
         justify-content: center;
         max-height: 295px;
         padding-top: 89px;
         padding-bottom: 48px; }
      &__text {
         margin: 0 auto;
         text-align: center;
         margin-bottom: 19px;
         &_policy {
            margin: 0 auto;
            order: 1; } } }
   .map-info {
      width: 344px;
      max-width: 100%;
      padding: 76px 0 73px 0;
      &__wrapper {
         margin-left: 42px;
         margin-right: 20px; }
      &__social {
         margin-top: 40px; } }
   .social-list__round:first-child {
      margin-right: 9px; }
   .btn_hidden-footer {
      position: absolute;
      bottom: -60px;
      left: 276px;
      //display: flex
      z-index: 2; } }

@media ( max-width: 767px ) {
   #map {
      height: 727px; }
   .footer {
      padding-top: 96px;
      &__line {
         max-height: 367px;
         padding-top: 238px;
         padding-bottom: 31px; }
      &__info {
         bottom: -134px; } }
   .map-info {
      right: 50%;
      transform: translateX(50%);
      padding: 76px 0 67px 0;
      width: 335px;
      max-width: 100%;
      &__wrapper {
         margin-left: 0;
         margin-right: 0; }
      &__img {
         display: flex;
         width: 180px;
         height: 143px;
         margin-left: auto;
         margin-right: auto; }
      &__title {
         margin-left: 66px;
         margin-right: 30px; }
      &__text, &__block, &__phone {
         margin-left: 66px;
         margin-right: 30px;
         font-size: 13px;
         line-height: 168%; }
      &__block > .map-info__text {
         margin-left: 0;
         margin-right: 0; }
      &__round {
         margin-top: 3px; }
      &__social {
         justify-content: center;
         margin-top: 14px; } }
   .btn_hidden-footer {
      bottom: -159px;
      left: auto;
      right: 50%;
      transform: translateX(50%); } }
@media ( max-width: 370px ) {
   .map-info {
      padding: 30px 0 50px 0;
      width: 320px;
      &__title {
         margin-left: 20px; }
      &__text, &__block, &__phone {
         margin-left: 20px;
         margin-right: 20px; } } }
