.slider {
   &__item {
      position: relative;
      width: 380px;
      background-repeat: no-repeat;
      background-size: cover;
      &:not(:last-child) {
         padding-right: 2px; } }
   &__image {
      height: 380px;
      background-size: cover; }
   &__service {
      display: block;
      margin: 0 auto;
      margin-top: 15px;
      font-size: 17px;
      opacity: .5;
      text-align: center; }
   &__info {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 404px;
      height: 255px;
      box-sizing: border-box;
      padding: 0 60px;
      padding-top: 57px;
      line-height: 143%;
      font-size: 17px; }
   &__title {
      display: block;
      margin-bottom: 25px;
      font-weight: 600; }

   &__progress {
      margin-top: 35px;
      height: 2px;
      background-color: #DEE7EA; } }

