.review {
   &__rating {
      @include flex-center;
      width: 140px;
      height: 130px;
      background-color: $green;
      border-radius: 50%; }
   &__svg {
      height: 64px;
      width: 64px;
      fill: white; }
   &__list {
      @include row-flex(); }
   &__item {
      @include col();
      @include size(3);
 }      // +size-lg(4)

   &__block {
      position: relative;
      display: flex;
      justify-content: center;
      margin-top: 90px;
      margin-bottom: 125px; }
   &__text {
      position: absolute;
      top: -62px; } }
@media ( max-width: 1439px ) {
   .review {
      &__block {
         margin-top: 80px;
         margin-bottom: 112px; }
      &__item {
         @include size(4); }
      &-item {
         &:last-child {
            display: none; }
         &__rating {
            margin-bottom: 39px; }
         &__author {
            font-size: 15px; }
         &__text {
            font-size: 15px; } } } }
@media ( max-width: 970px ) {
   .review {
      &__block {
         margin-top: 70px;
         margin-bottom: 102px; }
      &__item {
         margin-left: 10px;
         margin-right: 10px; }
      &-item {
         width: 277px; } } }
@media ( max-width: 767px ) {
   .review {
      &__rating {
         width: 110px;
         height: 101px; }
      &__svg {
         height: 50px;
         width: 50px; }
      &__block {
         margin-top: 61px;
         margin-bottom: 82px; }
      &__item {
         margin-left: 5px;
         margin-right: 5px; }
      &-item {
         width: 220px;

         padding-left: 20px;
         &__text {
            font-size: 13px; } }
      &__text {
         top: -110px;
         font-size: 15px; } } }


