.about {
   &__subtitle {
      margin: 95px auto; }
   &__list {
      @include row-flex(); }

   &__item {
      @include col();
      @include size(4); } }

@media ( max-width: 1440px ) {
   .about {
      &__subtitle {
         margin-top: 80px;
         margin-bottom: 80px;
         font-size: 20px; }
      &-item {
         // padding: 50px 28px 40px 28px
         &__wrapper {
            padding: 0 28px 0 28px;
            height: 500px; }
         &__title {
            // padding: 0 28px
            padding-top: 50px;
            margin-bottom: 30px;
            font-size: 26px;
            line-height: 34px; }
         &__list {
            // padding-left: 53px
 }            // padding-right: 28px
         &__item {
            font-size: 17px; }
         &__price {
            // padding: 0 28px
            padding-bottom: 40px;
            font-size: 22px; }
         &__item {
            &:before {
               top: 8px; } } } } }
@media ( max-width: 970px ) {
   .about {
      &__subtitle {
         margin-top: 70px;
         margin-bottom: 70px; }
      &__item {
         margin-left: 10px;
         margin-right: 10px; }
      &-item {
         width: 334px; } } }
@media ( max-width: 767px ) {
   .about {
      &__subtitle {
         margin-top: 50px;
         margin-bottom: 50px;
         font-size: 18px; }
      &__item {
         margin-left: 5px;
         margin-right: 5px; }
      &-item {
         max-width: 335px;
         // padding: 52px 22px 40px 22px
         &__wrapper {
            padding: 0 22px 0 22px;
            height: 450px; }
         &__title {
            font-size: 24px;
            line-height: 31px; }
         &__item {
            font-size: 15px; }
         &__price {
            font-size: 17px; } } } }
@media ( max-width: 370px ) {
   .about {
      &-item {
         max-width: 280px; }
      &__hand {
         fill: #394937; } } }
