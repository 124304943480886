.link {
   width: 152px;
   height: 152px;
   display: flex;
   align-items: center;
   justify-content: center;
   border-radius: 50%;
   border: none;

   //cursor: none
   text-align: center;
   letter-spacing: 0.08em;
   text-transform: uppercase;
   background: #000000;
   color: #fff;
   font-weight: bold;
   font-size: 14px;
   line-height: 143%;
   transform: scale(0);
   transition: 0.2s;

   &-wrapper {
      position: fixed;
      pointer-events: none;
      transform: translate(-50%,-50%);
      width: 152px;
      height: 152px;
      z-index: 9999;
      overflow: hidden; }
   &__inner {
      transform: translateY(-100px);
      transition: .2s;
      &_active {
         transform: translateY(0px); } }

   &_drag {
      width: 120px;
      height: 120px;
      &::before {
         content: url("data:image/svg+xml,%3Csvg width='13' height='20' viewBox='0 0 13 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.3127 16.244L3 9L11.3127 1.75604' stroke='white' stroke-width='3'/%3E%3C/svg%3E");
         margin-right: 20px; }

      &::after {
         content: url("data:image/svg+xml,%3Csvg width='13' height='20' viewBox='0 0 13 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.3127 16.244L3 9L11.3127 1.75604' stroke='white' stroke-width='3'/%3E%3C/svg%3E");
         transform: scale(-1, 1);
         margin-left: 20px; } } }
