.team {
   display: none; //ВРЕМЕННО
   padding-top: 100px;
   &__subtitle {
      margin-bottom: 105px; }
   &__list {
      @include row-flex();
      &_hidden {
         display: none; } }
   &__item {
      @include col();
      @include size(3);
      @include size-lg(4);
      @include size-md(4);
      @include size-xs(6);
      margin-bottom: 88px;
      &_hidden {
         display: none; } }
   &__btn {
      margin: 0 auto; } }

@media ( max-width: 1439px ) {
   .team {
      padding-top: 139px;
      &__subtitle {
         margin-bottom: 82px; }
      &-item {
         margin-bottom: 80px;

         &__image {
            flex-shrink: 0;
            width: 158px;
            height: 158px;
            margin-bottom: 49px; }
         &__experience {
            margin-bottom: 5px;
            font-size: 10px; }
         &__name {
            font-size: 15px; }
         &__text {
            margin-bottom: 30px;
            font-size: 15px; }
         &__spec {
            font-size: 15px; } } } }

@media ( max-width: 970px ) {
   .team {
      padding-top: 94px;
      &__subtitle {
         margin-bottom: 130px; }
      &-item {
         margin-bottom: 50px;
         &__image {
            margin-bottom: 30px; }
         &__experience {
            margin-bottom: 10px; } } } }
@media ( max-width: 767px ) {
   .team {
      padding-top: 91px;
      &__subtitle {
         margin-bottom: 52px;
         font-size: 18px; }
      &-item {
         margin-bottom: 42px;
         &__info {
            padding-left: 20px; }
         &__image {
            min-width: 143px;
            width: 143px;
            height: 143px;
            margin-bottom: 45px; }
         &__name {
            line-height: 133%; }
         &__text {
            margin-bottom: 20px;
            font-size: 13px; }
         &__spec {
            font-size: 13px;
            line-height: 160%; } } } }
@media ( max-width: 370px ) {
   .team {
      &-item {
         &__image {
            min-width: 130px;
            width: 130px;
            height: 130px; } } } }
