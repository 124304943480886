.advantage-save {
    color: #000000;
    position: relative;
    font-size: 17px;
    line-height: 168%;
    &::before {
        display: block;
        position: absolute;
        content: '';
        width: 10px;
        height: 10px;
        left: 0px;
        top: 11px;
        border-radius: 50%;
        box-sizing: border-box;
        border: 3px solid #D5B5F3; } }

