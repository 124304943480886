.hero {
    display: block;
    max-width: 516px;
    text-align: center;
    &__lettering {
        display: block;
        margin: 0 auto 33px auto; }
    &__text {
        font-size: 23px;
        line-height: 168%; } }
