.about-item {
   display: flex;
   justify-content: center;
   &__wrapper {
      display: flex;
      flex-direction: column;
      padding: 0 59px 0 59px;
      // padding-bottom: 0
      height: 600px; }
   &__title {
      display: block;
      padding-top: 76px;
      // padding: 0 59px
      margin-bottom: 33px;
      font-family: $secondary-font;
      font-size: 30px;
      line-height: 39px;
      letter-spacing: 0.02em; }
   &__list {
      padding-left: 25px;
      // padding-left: 84px
 }      // padding-right: 59px
   &__item {
      display: block;
      position: relative;
      margin-bottom: 15px;
      font-size: 20px;
      line-height: 160%;
      &:before {
         @include pseudo();
         width: 10px;
         height: 10px;
         left: -25px;
         top: 12px;
         border-radius: 50%;
         box-sizing: border-box; }
      &_purple {
         &:before {
           border: 3px solid #D0AFCB; } }
      &_orange {
         &:before {
           border: 3px solid #F0B2AF; } }
      &_blue {
         &:before {
           border: 3px solid #98CBD7; } } }
   &__price {
      margin-top: auto;
      // padding-left: 59px
      padding-bottom: 76px;
      font-size: 24px;
      font-weight: 600; } }
