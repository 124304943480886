.map-info {
   display: flex;
   flex-direction: column;
   width: 542px;
   max-width: 100%;
   padding: 105px 0 82px 0;
   z-index: 1;
   background: $bg-purple;
   font-size: 17px;
   line-height: 168%;
   &__wrapper {
      margin-left: 92px; }
   &__img {
      width: 254px;
      height: 201px;
      margin-bottom: 44px;
      line-height: 1; }
   &__phone {
      margin-top: 15px; }
   &__text {
      display: block; }
   &__title {
      display: block;
      margin-bottom: 15px;
      font-weight: bold; }
   &__block {
      display: flex;
      align-items: flex-start; }
   &__round {
      @include flex-center;
      width: 15px;
      height: 15px;
      margin-top: 6px;
      margin-right: 5px;
      background: #394937;
      border-radius: 50%;
      fill: #E7D8E5; }
   &__icon-metro {
      width: 9px;
      height: 7px; }
   &__icon-parking {
      width: 6px;
      height: 7px; }
   &__social {
      margin-top: 62px;
      align-self: flex-start; } }

