.header {
   display: flex;
   flex-direction: column;
   position: relative;
   height: 100vh;
   padding-top: 25px;
   box-sizing: border-box;
   background-color: #FEE9E8;
   &__topline {
      position: relative;
      z-index: 3;
      width: 100%; }
   &>.wrapper {
      flex-grow: 1;
      width: 100%; }
   &__inner {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      height: 100%;
      width: 100%; }

   &__go-down {
      position: absolute;
      right: 0;
      bottom: 0;
      z-index: 2; }

   &__slider {
      position: absolute;
      bottom: 0;
      right: 0;
      height: 100%;
      width: 100%; }
   &__slide {
      background-repeat: no-repeat;
      background-position: right bottom;
      &_1 {
         background-image: url('../img/hero-slider.jpg'); }
      &_2 {
         background-image: url('../img/hero-slider2.png'); }
      &_3 {
         background-image: url('../img/hero-slider3.png'); } }

   &-pagination {
      position: absolute;
      bottom: 30px !important;
      z-index: 2;
      text-align: center; }
   &__bullet {
      width: 32px;
      height: 32px;
      opacity: 1;
      &.swiper-pagination-bullet {
         background-color: transparent;
         .bullet-ring {
            opacity: 0;
            transform: rotate(-90deg); }
         &-active {
            .bullet-ring {
               opacity: 1;
               transform-origin: center;
               animation: ring 5s forwards;
               stroke-dasharray: 0 1000;
               &-shadow {
                  opacity: 0.1;
                  animation: none;
                  stroke-dasharray: 1000 1000 !important; } } } } } }

@keyframes ring {
   0% {
      // transform: scale(1) rotate(-90deg)
      stroke-dasharray: 0 1000; }
   80% {
      // transform: scale(1) rotate(-90deg)
      stroke-dasharray: 100 1000; }
   100% {
      // transform: scale(0) rotate(-90deg)
      stroke-dasharray: 100 1000; } }



.topline {
   @include flex-sb-c;
   box-sizing: border-box;
   padding-left: 50px;
   padding-right: 50px;
   &__location {
      margin-right: auto; }
   &__phone-block {
      display: flex; }
   &__phone {
      margin-left: 27px; } }
.hero {
   position: relative;
   z-index: 2; }

@media ( max-width: 1439px ) {
   .header {
      // &__hero-block
 }      //    position: relative
   .topline {
      padding-left: 30px;
      padding-right: 30px; }
   .hero {

      max-width: 383px;
      &__lettering {
         max-width: 340px; } }
   .hero__text {
      font-size: 20px; } }
@media ( max-width: 970px ) {
   .header > .wrapper {
      padding-left: 0;
      padding-right: 0; }
   .go-down {
      padding-bottom: 39px;
      transform: rotate(270deg) translateY(140%); }
   .topline {
      flex-wrap: wrap;
      justify-content: center; }
   .location {
      justify-content: center;
      width: 100%;
      margin-bottom: 19px;
      &__round {
         display: none; } }
   .header {
      &__inner {
         position: relative;
         // flex-wrap: wrap
         flex-direction: column;
         justify-content: space-between; }

      &__hero-block {
         width: 100%;
         margin-top: 94px;
         background-color: #FEE9E8; }
      &__slider {
         //position: relative
         // margin-top: auto
         //max-height: 50%
         background-color: #FEE9E8; }

      &__slide {
         background-size: contain;
         background-position: bottom; } }
   .hero {
      margin: 0 auto; } }

@media ( max-width: 767px ) {
   .wrapper {
      padding-left: 20px;
      padding-right: 20px; }
   .go-down {
      padding-bottom: 20px;
      transform: rotate(270deg) translateY(200%);
      font-size: 12px; }
   .topline {
      flex-wrap: no-wrap;
      justify-content: space-between;
      padding-left: 20px;
      padding-right: 20px;
      &__phone {
         font-size: 12px; } }
   .location {
      margin-bottom: 0;
      margin-right: 0;
      width: 30px;
      &__round {
         display: flex;
         flex-shrink: 0;
         width: 30px;
         height: 30px;
         margin-right: 0; }
      &__mark {
         width: 12px;
         height: 14px; }
      &__city {
         display: none; } }
   .work-time__text {
      display: none;
      &:first-child {
         display: none; } }
   .hero {
      max-width: 275px;
      &__lettering {
         max-width: 206px;
         margin-bottom: 11px; }
      &__text {
         font-size: 18px; } }
   .header {
      &__hero-block {
         margin-top: 74px;
 } } }         // top: 74px
@media ( max-width: 480px ) {
   .header {
      &__hero-block {
         margin-top: 54px; } } }
@media ( max-width: 370px ) {
   .topline__phone {
      margin-left: 15px; }
   .swiper-container-horizontal>.swiper-pagination-bullets, .swiper-pagination-custom, .swiper-pagination-fraction {
      display: none; }
   .btn_hidden-header {
      bottom: 20px; }
   .header__hero-block {
      margin-top: 20px;
 }      // margin-bottom: 120px
   .hero__text {
      padding: 0 10px;
      font-size: 14px; }
   .hero__lettering {
      width: 164px;
      height: 130px;
      margin-bottom: 0; } }
