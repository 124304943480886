.price {
   max-width: 600px;
   overflow-y: hidden;
   padding-bottom: 60px;
   &__title {
      display: block;
      padding: 60px;
      font-family: $secondary-font;
      font-weight: bold;
      font-size: 30px;
      line-height: 39px;
      letter-spacing: 0.02em; }
   &__advantage {
      display: flex;
      margin-bottom: 60px;
      padding: 0 60px; }
   &__advantage-item {
      width: 33.33%;
      margin-right: 25px; }
   &__round {
      width: 19.04px;
      height: 19.04px;
      margin-bottom: 23px;
      border: 5px solid #ffffff;
      border-radius: 50%; }
   &__text {
      display: block;
      font-size: 17px;
      line-height: 168%; }
   &__list {
      margin-bottom: 50px;
      padding: 0 60px; }
   &__item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 15px 0;
      border-bottom: 2px solid #FFFFFF;
      &:last-child {
         border-bottom: none; } }
   &__name {
      display: inline;
      font-size: 17px;
      line-height: 168%;
      font-weight: 400;
      &_light {
         display: block;
         font-size: 13px;
         color: darkgrey; } }
   &__fatname {
      display: block;
      width: 60%;
      font-size: 17px;
      line-height: 168%;
      font-weight: 600; }
   &__number {
      display: block;
      width: 40%;
      text-align: right; }
   &__btn {
      margin: 0 auto; }
   &__skin, &__eyebrows {
      color: #000000; } }
#nails > .scrollbar-outer > .scroll-element.scroll-y .scroll-element_outer {
   top: 20px;
   bottom: 20px;
   height: calc( 100vh - 40px );
   width: 14px; }
#nails > .scrollbar-outer > .scroll-element.scroll-y .scroll-bar { //цвет бегунка
   background: linear-gradient(90deg, #E7D8E5 33%, #D0AFCB 33%, #D0AFCB 66%, #E7D8E5 66%);
   //background: #D0AFCB
   max-height: 530px;
   width: 14px; }
#nails >.scrollbar-outer > .scroll-element.scroll-y {  //цвет бордера
   padding-right: 20px;
   background: #E7D8E5; }
#nails >.scrollbar-outer > .scroll-element.scroll-y .scroll-element_track {    //цвет трека
   margin-left: 5px;
   width: 4px;
   opacity: 0.4;
   background: #D0AFCB; }
#skin > .scrollbar-outer > .scroll-element.scroll-y .scroll-element_outer {
   top: 20px;
   bottom: 20px;
   height: calc( 100vh - 40px );
   width: 14px; }
#skin > .scrollbar-outer > .scroll-element.scroll-y .scroll-bar { //цвет бегунка
   //background: #F0B2AF
   background: linear-gradient(90deg, rgba(254,233,232,1) 33%, rgba(240,178,175,1) 33%, rgba(240,178,175,1) 66%, rgba(254,233,232,1) 66%);
   max-height: 530px;
   width: 14px; }
#skin > .scrollbar-outer > .scroll-element.scroll-y {  //цвет бордера
   padding-right: 20px;
   background: #FEE9E8; }
#skin > .scrollbar-outer > .scroll-element.scroll-y .scroll-element_track {    //цвет трека
   margin-left: 5px;
   width: 4px;
   opacity: 0.4;
   background: #F0B2AF; }
#eyebrows > .scrollbar-outer > .scroll-element.scroll-y .scroll-element_outer {
   top: 20px;
   bottom: 20px;
   height: calc( 100vh - 40px );
   width: 14px; }
#eyebrows > .scrollbar-outer > .scroll-element.scroll-y .scroll-bar { //цвет бегунка
   background: linear-gradient(90deg, #DAF4FA 33%, #98CBD7 33%, #98CBD7 66%, #DAF4FA 66%);
   //background: #98CBD7
   max-height: 530px;
   width: 14px; }
#eyebrows > .scrollbar-outer > .scroll-element.scroll-y {  //цвет бордера
   padding-right: 20px;
   background: #DAF4FA; }
#eyebrows > .scrollbar-outer > .scroll-element.scroll-y .scroll-element_track {    //цвет трека
   margin-left: 5px;
   width: 4px;
   opacity: 0.4;
   background: #98CBD7; }
