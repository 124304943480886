.section-title {
    max-width: 480px;
    margin: 0 auto;
    margin-bottom: 20px;
    font-family: $secondary-font;
    font-weight: bold;
    font-size: 29px;
    line-height: 39px;
    text-align: center;
    letter-spacing: 0.02em;
    &_big {
        max-width: 717px;
        font-size: 47px;
        line-height: 130%; } }
