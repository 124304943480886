.work-time {
    display: flex;
    &__text {
        display: block;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        &:first-child {
            @include flex-center;
            &::after {
                content: '';
                display: block;
                width: 24px;
                height: 2px;
                margin: 0 5px;
                background: #394937; } } } }
