$complex-color: #5380F8;

.complex-item {
   @include flex-col-c;
   background-color: #6C8FEB;
   padding: 57px 35px 37px;
   color: #fff;
   &__type {
      margin-bottom: 25px;
      font-size: 14px;
      font-weight: 600;
      text-transform: uppercase;
      letter-spacing: 0.08em; }
   &__title {
      font-family: $secondary-font;
      font-size: 48px;
      font-weight: 600;
      letter-spacing: 0.02em;
      text-align: center; }
   &__list {
      @include flex-col-c;
      margin-bottom: 38px;
      margin-top: 30px; }
   &__item {
      display: block;
      position: relative;
      margin-top: 5px;
      margin-bottom: 5px;
      padding: 9px 36px;
      text-align: center;
      font-size: 14px;
      font-weight: 600;
      line-height: 143%;
      letter-spacing: 0.08em;
      text-transform: uppercase;
      background-color: $complex-color;
      border-radius: 12px;
      &:before, &:after {
         @include pseudo;
         width: calc(100% - 12px);
         height: 16px;
         left: 6px;
         background-color: $complex-color; }
      &:after {
         top: -6px;
         border-top-left-radius: 50%;
         border-top-right-radius: 50%; }
      &:before {
         bottom: -6px;
         border-bottom-left-radius: 50%;
         border-bottom-right-radius: 50%; }
      &:first-child {
         padding-top: 15px;
         &:after {
            display: none; } }
      &:last-child {
         padding-bottom: 15px;
         &:before {
            display: none; } } }
   &__connector {
      display: block;
      margin: 0 auto;
      width: 50px;
      height: 27px;
      fill: $complex-color; }
   &__discount {
      @include flex-center;
      width: 125px;
      height: 115px;
      margin-top: auto;
      margin-right: auto;
      margin-left: auto;
      border-radius: 50%; }
   &__number {
      width: 87px;
      height: 59px;
      fill: #5380F8; } }

