.review-item {
   padding: 40px;
   background-color: #F9F0E8;
   &__rating {
      margin-bottom: 30px; }
   &__author {
      display: block;
      margin-bottom: 18px;
      font-size: 18px;
      font-weight: 600; }
   &__text {
      margin: 0;
      font-size: 17px;
      line-height: 168%;
      opacity: 0.5; }
   &__icon {
      &_star {
         fill: #F5AB1B; }
      &_star-empty {
         fill: #EFD8D0; } } }
