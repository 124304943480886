.thanks {

   display: flex;
   flex-direction: column;
   height: calc(100% - 166px);
   padding: 166px 60px 0 60px;
   background: #FEE9E8;
   &__name, &__title {
      display: block;
      font-family: $secondary-font;
      font-weight: bold;
      font-size: 30px;
      line-height: 39px;
      letter-spacing: 0.02em; }
   &__text {
      display: block;
      margin: 45px 0 60px;
      font-size: 23px;
      line-height: 168%; }
   &__light-text {
      display: block;
      padding-bottom: 39px;
      font-size: 17px;
      line-height: 168%;
      opacity: 0.5; }
   &__subscription, &__link {
      display: block;
      font-size: 23px;
      line-height: 168%;
      color: #FEE9E8; }
   &__block {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 276px;
      margin-top: auto;
      margin-right: -60px;
      margin-left: -60px;
      background: #394937; }
   &__link {
      font-weight: bold;
      color: #FEE9E8; }
   &__icon {
      @include flex-center;
      width: 70px;
      height: 70px;
      margin-right: 34px;
      background: #FEE9E8;
      border-radius: 50%; }
   &__svg {
      width: 21px;
      height: 21px;
      fill: #394937; } }
