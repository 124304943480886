#form {
   padding: 78px 100px 107px; }
#form-popup {
   padding-bottom: 127px;
   box-sizing: border-box; }
.form {
   position: relative;
   display: flex;
   justify-content: space-between;
   background-color: #FEE9E8;
   // overflow: hidden

   &__title {
      max-width: 45%;
      // padding-right: 220px
      box-sizing: border-box;
      font-family: $secondary-font;
      font-size: 48px;
      line-height: 130%; }

   &__body {
      opacity: 0;
      transition: opacity .3s;
      &_visible {
         opacity: 1; } }
   &__time {
      display: block;
      margin-bottom: 25px;
      margin-left: 34px;
      font-size: 14px;
      color: #ED7373; }
   &__line {
      position: relative;
      margin-bottom: 15px;
      &.inp-ok {
         .form__valid_ok {
            opacity: 1; } }
      &.inp-error {
         .form__valid_error {
            opacity: 1; }
         input {
            background-color: #ED7373; } } }
   &__valid {
      @include flex-center;
      width: 23px;
      height: 23px;
      position: absolute;
      right: 25px;
      top: 50%;
      transform: translateY(-50%);
      border-radius: 50%;
      opacity: 0;
      &_ok {
         background-color: #FEE9E8; }
      &_error {
         background-color: #fff; } }

   &__icon {
      &_arrow {
         position: absolute;
         right: 34px;
         top: calc(50% - 5px);
         width: 10px;
         height: 10px;
         fill: none;
         stroke: #394937;
         transform: scaleY(1);
         transition: transform .4s;
         &-flip {
            transform: scaleY(-1); } }
      &_ok {
         width: 11px;
         height: 11px; }
      &_error {
         width: 9px;
         height: 9px;
         fill: #ED7373; } }

   &__btn {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 50%); }
   &__personal {
      display: block;
      font-size: 12px;
      opacity: 0.4;
      text-align: center; }
   &__personal-link {
      display: block;
      font-size: 12px;
      opacity: 0.4;
      text-align: center;
      &:hover {
         opacity: 1; } } }
.inp-ok {}

.inp-error {
   .form__valid_error {
      opacity: 1; } }

