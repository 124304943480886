.service {
   padding-top: 90px;
   &__subtitle {
      margin-bottom: 60px; }
   &__list {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-top: 133px; }
   &__item {
      margin-bottom: 30px; }
   &__btn {
      margin: 0 auto; } }

@media ( max-width: 1200px ) {
   // .popup-link .service-item .service-item_big .service__item .hover

   .service {
      padding-top: 80px;
      &__title {
         margin-bottom: 24px;
         font-size: 26px; }
      &__subtitle {
         font-size: 20px; }
      &__list {
         margin-top: 100px; }
      &-item {
         &_big {
            height: 454px;
            & .service-item__title {
               font-size: 26px;
               line-height: 34px; }
            & .service-item__subtitle {
               margin-bottom: 16px;
               font-size: 15px; } }
         &_small {
            // height: 300px
            & .service-item__title {
               font-size: 15px; }
            & .service-item__content {
               display: flex;
               flex-direction: column;
               height: 100%;
               padding: 50px 0;
               padding-right: 25px;
               box-sizing: border-box; }
            & .service-item__subtitle {
               margin-bottom: 16px;
               font-size: 15px; } }
         &__time {
            font-size: 15px; }
         &__price {
            margin-top: auto;
            font-size: 22px; }
         &__timeblock {
            margin-bottom: 20px; } } } }
@media ( max-width: 970px ) {
   .service {
      padding-top: 70px;
      &__subtitle {
         font-size: 20px; }
      &-item {
         &_big {
            background-position: -140px !important;
 }            // padding-right: 45px
         &_small {
            // height: 300px
            background-image: none !important;
            & .service-item__title {
               font-size: 18px; }
            & .service-item__content {
               margin-left: 53px; } } } } }

@media ( max-width: 767px ) {
   .service {
      padding-top: 50px;
      &__title {
         margin-bottom: 13px;
         font-size: 24px; }
      &__subtitle {
         margin-bottom: 40px;
         font-size: 18px; }
      &__list {
         margin-top: 70px; }
      &-item {
         &_big {
            // padding-right: 20px
            height: 250px;
            // background-size: 250px !important
            // background-position: left bottom !important
            background-position: -90px !important;
            & .service-item__title {
               font-size: 15px;
               line-height: 133%; }
            & .service-item__content {
               margin-left: 53%; }
            & .service-item__subtitle {
               margin-bottom: 14px;
               font-size: 13px; } }
         &_small {
            width: calc(50% - 10px);
            // height: 250px
            & .service-item__title {
               font-size: 15px; }
            & .service-item__content {
               margin-left: 20px;
               margin-right: 20px; }
            & .service-item__subtitle {
               margin-bottom: 14px;
               font-size: 13px; } }
         &__title {
            margin-bottom: 5px;
            &_big {
               font-size: 15px; } }
         &__subtitle {
            margin-bottom: 14px;
            font-size: 13px;
            line-height: 160%; }
         &__time {
            font-size: 13px; }
         &__price {
            font-size: 17px; }
         &__timeblock {
            margin-bottom: 16px; } } } }

@media ( max-width: 420px ) {
   .service {
      &-item {
         &_small {
            width: calc(50% - 5px);
            & .service-item__content {
               margin: 0;
               padding: 23px 20px; } }
         &_big {
            background-position: -70px bottom !important; } } } }

