.advantage-item {
    text-align: center;
    font-size: 17px;
    &__title {
        display: block;
        margin-top: 32px;
        margin-bottom: 15px;
        font-weight: bold;
        line-height: 168%; }
    &__text {
        display: block;
        line-height: 168%; } }
