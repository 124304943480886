.warranty {
   position: relative;
   display: flex;
   height: 600px;
   &__left {
      display: flex;
      width: 50%;

      background: #394937; }
   &__left-wrapper {
      width: 432px;
      margin: 0 auto;
      padding-left: 50px; }
   &__number {
      position: relative;
      left: -20px;
      width: 132px;
      height: 191px;
      margin-top: 96px;
      fill: #DAF4FA; }
   &__text {

      margin-left: -35px;
      color: #DAF4FA;
      font-family: $secondary-font;
      font-weight: bold;
      font-size: 68px;
      line-height: 130%;
      letter-spacing: 0.02em; }
   &__right {
      display: flex;
      justify-content: center;
      width: 50%;
      background: #DAF4FA; }
   &__right-wrapper {
      width: 420px; }
   &__title {
      display: block;
      margin-top: 141px;
      margin-bottom: 40px;
      font-weight: bold;
      font-size: 23px;
      line-height: 168%; }
   &__list {
      padding-left: 30px; }
   &__list-item {
      position: relative;
      font-size: 23px;
      line-height: 168%;
      &::before {
         position: absolute;
         content: '';
         width: 10px;
         height: 10px;
         left: -30px;
         top: 18px;
         border-radius: 50%;
         box-sizing: border-box;
         border: 3px solid #98CBD7; } }
   &__button {
      position: absolute;
      bottom: -76px;
      transform: translateX(50%);
      right: 50%; } }



