// Добавление шрифтов
@mixin font-face($font-family, $file-path, $weight: normal, $style: normal, $asset-pipeline: false ) {
	@font-face {
		font-family: $font-family;
		font-weight: $weight;
		font-style: $style;

		@if $asset-pipeline == true {
			src: font-url('#{$file-path}.eot');
			src: font-url('#{$file-path}.eot?#iefix') format('embedded-opentype'), font-url('#{$file-path}.woff') format('woff'), font-url('#{$file-path}.ttf') format('truetype'); }
		@else {
			src: url('#{$file-path}.eot');
			src: url('#{$file-path}.eot?#iefix') format('embedded-opentype'), url('#{$file-path}.woff') format('woff'), url('#{$file-path}.ttf') format('truetype'); } } }

// Шаблон для абсолютно спозиционированного псевдоэлемента
@mixin pseudo {
	display: block;
	position: absolute;
	content: ''; }

@mixin flex-center {
	display: flex;
	justify-content: center;
	align-items: center; }

@mixin flex-sb-c {
	display: flex;
	justify-content: space-between;
	align-items: center; }

@mixin flex-col-c {
	display: flex;
	flex-direction: column;
	align-items: center; }

@mixin caret {
	@include pseudo;
	z-index: 2;
	right: 15px;
	top: 50%;
	width: 7px;
	height: 7px;
	border-right: 2px solid var(--text-color);
	border-bottom: 2px solid var(--text-color);
	transform: translateY(-70%) rotate(45deg); }

@mixin keyframes($name) {
	@-webkit-keyframes #{$name} {
		@content; }
	@-moz-keyframes #{$name} {
		@content; }
	@-ms-keyframes #{$name} {
		@content; }
	@keyframes #{$name} {
		@content; } }
