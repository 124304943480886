.team-item {
   display: flex;
   flex-direction: column;
   &__image {
      display: block;
      width: 187px;
      height: 187px;
      margin-bottom: 45px; }
   &__info {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      padding-left: 40px; }
   &__experience {
      display: block;
      margin-bottom: 10px;
      font-size: 12px;
      font-weight: 600;
      text-transform: uppercase;
      letter-spacing: 0.08em; }
   &__name {
      display: block;
      margin-bottom: 25px;
      font-size: 17px;
      font-weight: 600; }
   &__text {
      display: block;
      margin-bottom: 22px;
      margin-top: 0;
      font-size: 17px;
      line-height: 168%;
      opacity: .5; }
   &__spec {
      display: block;
      margin-top: auto;
      font-size: 17px; } }
