.advantage {
   &__list-item {
      @include row-flex(); }
   &__item {
      @include col();
      @include size(4); }
   &__subtitle {
      margin-bottom: 60px;
      margin-top: 175px; }
   &__icon {
      width: 125px;
      height: 125px; }
   &__title-time {
      margin-bottom: 36px;
      margin-top: 160px; }
   &__warranty {
      margin-bottom: 216px;
      margin-top: 149px; }
   &__brands-block {
      @include row-flex(); }
   &__brands-list {
      @include col();
      @include shift(1);
      @include size(10);

      @include flex-center;
      flex-wrap: wrap; }

   &__block {
      display: flex; }
   &__title {
      margin-bottom: 79px;
      color: #000000; }
   &__save-list {
      @include row-flex();
      justify-content: center; }

   &-save__item {
      @include col();
      @include size(3);
      @include size-lg(3);
      padding-left: 30px;
      &:nth-child(odd) {
         @include shift-left(3);
         @include shift-lg-left(3); }
      &:nth-child(even) {
         @include shift-right(3);
         @include shift-lg-right(3); }
      &:last-child, &:nth-child(3) {
        margin-top: 50px; } } }

@media ( max-width: 1200px ) {
   .advantage {
      &__title {
         margin-bottom: 82px;
         font-size: 26px; }
      &__subtitle {
         margin-bottom: 76px;
         margin-top: 168px;
         font-size: 20px; }
      &-item {
         font-size: 15px;
         &__text, &__title {
               line-height: 143%; } }
      &__warranty {
         margin-bottom: 180px;
         margin-top: 81px; }
      &__save-list {}
      &-save__item {
         @include size-md(4);
         font-size: 15px;
         &:last-child, &:nth-child(3) {
            margin-top: 62px; }
         &:nth-child(odd) {
            @include shift-md-left(2); }
         &:nth-child(even) {
            @include shift-md-right(2); }
         &::before {
            top: 9px; } }
      &__title-time {
         margin-bottom: 41px;
         margin-top: 80px;
         font-size: 26px;
         line-height: 34px; }
      &__brands-list {
         margin: 0 auto; } }
   .warranty {
      height: 510px;
      &__left-wrapper {
         width: 315px; }
      &__number {
         left: -20px;
         width: 94px;
         height: 136px;
         margin-top: 92px; }
      &__text {
         font-size: 48px; }
      &__right-wrapper {
         width: 320px; }
      &__title {
         margin-top: 131px;
         margin-bottom: 27px;
         font-size: 20px; }
      &__list-item {
         font-size: 15px;
         line-height: 1;
         &:not(:last-child) {
            margin-bottom: 15px; }
         &::before {
            top: 4px; } }
      &__button {
         bottom: -60px; } } }

@media ( max-width: 970px ) {
   .advantage {
      &__title {
         margin-bottom: 71px; }
      &__subtitle {
         margin-top: 164px; }
      &__warranty {
         margin-bottom: 160px;
         margin-top: 121px; }
      &-save__item {
         @include size-sm(4);
         &:nth-child(odd) {
            @include shift-sm-left(2); }
         &:nth-child(even) {
            @include shift-sm-right(2); } }
      &-save__item {
         &:last-child, &:nth-child(3) {
            margin-top: 37px; } }
      &__title-time {
         margin-bottom: 41px;
         margin-top: 44px; } }
   .warranty {
      height: 100%;
      flex-wrap: wrap;
      &__left {
         width: 100%;
         padding-bottom: 60px; }
      &__left-wrapper {
         width: 100%;
         padding-left: 0;
         text-align: center; }
      &__number {
         left: 0;
         width: 71px;
         height: 103px;
         margin-top: 58px;
         margin-bottom: 17px; }
      &__text {
         display: block;
         margin: 0 auto; }
      &__right {
         width: 100%;
         padding-bottom: 150px; }
      &__right-wrapper {
         margin: 0 auto; }
      &__title {
         margin-top: 60px; } }
   .brand {
      @include size(2.7); } }
@media ( max-width: 767px ) {
   .advantage {
      &__title {
         margin-bottom: 58px;
         font-size: 24px; }
      &__subtitle {
         margin-bottom: 61px;
         margin-top: 94px;
         font-size: 18px; }
      &__icon {
         width: 110px;
         height: 110px; }
      &-item {
         width: 100%;
         margin-bottom: 63px;
         &__text {
            max-width: 218px;
            margin: 0 auto;
            line-height: 133%;
            font-size: 13px; }
         &__title {
            margin-top: 30px;
            margin-bottom: 13px;
            line-height: 133%; } }
      &__list-item {
         flex-wrap: wrap; }
      &__warranty {
         margin-bottom: 96px;
         margin-top: 50px; }
      &-save__item {
         @include size-xxs(6);
         &:nth-child(odd) {
            @include shift-xxs-left(0); }
         &:nth-child(even) {
            @include shift-xxs-right(0); }
         padding-left: 0;
         padding-top: 4px;
         font-size: 13px;
         line-height: 160%;
         &:last-child, &:nth-child(3) {
            margin-top: 31px; }
         &::before {
            width: 8px;
            height: 8px;
            left: 0px;
            top: -10px; } }
      &__title-time {
         margin-bottom: 77px;
         margin-top: 50px;
         font-size: 24px;
         line-height: 31px; }
      &__brands-list {
         width: 100%; } }
   .wrapper-warranty {
      // padding-left: 0
 }      // padding-right: 0
   .warranty {
      margin-left: -20px;
      margin-right: -20px;
      &__left {
         padding-bottom: 50px; }
      &__left-wrapper {
         width: 245px;
         text-align: center; }
      &__number {
         width: 53px;
         height: 77px;
         margin-top: 40px;
         margin-bottom: 25px; }
      &__text {

         font-size: 26px; }
      &__right {
         padding-bottom: 88px;
         padding-left: 40px;
         padding-right: 40px; }
      &__right-wrapper {}
      &__title {
         margin-top: 46px;
         font-size: 18px; }
      &__list-item {
         font-size: 13px;
         &::before {
            top: 1px; } }
      &__button {
         bottom: -25px; }
      &__br {
         display: none; } }
   .brand {
      @include size(3.6); } }
@media ( max-width: 370px ) {
   .warranty {
      &__left-wrapper {
         width: 100%; }
      &__right {
         padding-left: 20px;
         padding-right: 20px; }
      &__right-wrapper {
         width: 100%; } } }
