* {
   -webkit-tap-highlight-color: transparent; }
div, a {
   outline: none; }
html {
   // width: 100%
   overflow-x: hidden; }
body {
   position: relative;
   min-width: 320px;
   font-family: $main-font;
   font-size: 16px;
   color: $green;
   overflow-x: hidden;
   overflow-y: auto; }

.wrapper {
   @include wrapper(); }

img {
   max-width: 100%;
   -webkit-user-select: none;
   -moz-user-select: -moz-none {
    /*IE10*/
    -ms-user-select: none;
    user-select: none; } }

a {
   text-decoration: none;
   color: inherit;
   &:hover {
      text-decoration: none; } }

ul {
   padding: 0;
   margin: 0; }
li {
   list-style-type: none; }
h1, h2, h3, h4 {
   margin: 0; }

button {
   display: inline-block;
   cursor: pointer;
   outline: none;
   transition: 0.3s;
   box-sizing: border-box;
   text-align: center;
   -webkit-user-select: none;
   -moz-user-select: none;
   -ms-user-select: none;
   user-select: none; }


.disabled {
   display: none !important; }

.active {
   display: block !important; }

.nowrap {
   white-space: nowrap; }

.input {
   width: 404px;
   height: 63px;
   padding: 0 34px;
   box-sizing: border-box;
   border: none;
   border-radius: 51px;
   //line-height: 63px
   text-transform: uppercase;
   font-size: 12px;
   font-weight: 600;
   letter-spacing: 0.08em;
   &:focus {
      background-color: #fff6f6; } }


.swiper {
   &-scrollbar {
      &-drag {
         background-color: #000; } } }

.select2-container {
   width: 404px !important;
   font-size: 12px;
   font-weight: 600;
   text-transform: uppercase;
   color: #000 !important;
   letter-spacing: 0.08em;

   .select2-selection {
      &--single {
         height: 63px;
         border: none !important;
         border-radius: 51px !important;
         padding: 0 34px;
         outline: none; }
      &__rendered {
         padding: 0 !important;
         line-height: 63px !important; }
      &__placeholder {
         color: #000 !important; } }

   &--default {
      .select2-selection {
         &__arrow {
            display: none; } }
      .select2-results__options {
         max-height: 400px !important; } }
   &--open {
      &.select2-container--above {
         .select2-selection {
            border-top-left-radius: 0 !important;
            border-top-right-radius: 0 !important;
            border-bottom-left-radius: 32px !important;
            border-bottom-right-radius: 32px !important; } }
      &.select2-container--below {
         .select2-selection {
            border-top-left-radius: 32px !important;
            border-top-right-radius: 32px !important;
            border-bottom-left-radius: 0 !important;
            border-bottom-right-radius: 0 !important; } } }

   .select2-dropdown {
      border: none;
      // padding-top: 0
      overflow: hidden;
      &--above {
         padding-top: 35px;
         border-top-left-radius: 51px;
         border-top-right-radius: 51px; }
      &--below {
         padding-bottom: 35px;
         border-bottom-left-radius: 40px;
         border-bottom-right-radius: 40px; }

      .select2-results__option {
         position: relative;
         padding: 14px 34px !important;
         border-radius: 25px;

         &:after {
            position: absolute;
            right: 32px;
            top: 50%;
            transform: translateY(-50%);
            content: attr(data-price); }
         &--highlighted {
            background-color: #fff;
            color: #5380F8; }
         &[aria-selected="true"] {
            background-color: #fff; } } } }

.select2-container--default .select2-results > .select2-results__options {
   overflow-y: hidden; }
// Скроллбар для дропдауна
.select2-results .scrollbar-outer > .scroll-element.scroll-y .scroll-bar { //цвет бегунка
   background: #F0B2AF; }
.select2-results .scrollbar-outer > .scroll-element.scroll-y > .scroll-element_outer {  //размер
   width: 4px; }
.select2-results .scrollbar-outer > .scroll-element.scroll-y .scroll-element_track {    //цвет трека
   opacity: 0.4;
   background: #F0B2AF; }
.scrollbar-outer > .scroll-element.scroll-y {
   padding-right: 31px; }
.scrollbar-outer > .scroll-content.scroll-scrolly_visible {
   padding-right: 20px; }

input::-webkit-input-placeholder, {
   color: #000; }
input::-moz-placeholder, {
   color: #000; }
input:-moz-placeholder, {
   color: #000; }
input:-ms-input-placeholder {
   color: #000; }

input:focus::-webkit-input-placeholder,
textarea:focus::-webkit-input-placeholder, {
   color: transparent; }
input:focus::-moz-placeholder,
textarea:focus::-moz-placeholder, {
   color: transparent; }
input:focus:-moz-placeholder,
textarea:focus:-moz-placeholder, {
   color: transparent; }
input:focus:-ms-input-placeholder,
textarea:focus:-ms-input-placeholder, {
   color: transparent; }

input::-ms-clear {
   display: none; }

input, textarea {
   -webkit-appearance: none;
   -webkit-border-radius: 0;
   outline: none;
   cursor: default; }

input {
   cursor: text !important; }

input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 50px #fff6f6 inset !important; }
input:-webkit-autofill, {
    -webkit-box-shadow: 0 0 0 50px white inset !important; }

