.popup {
   display: flex;
   height: 100vh;
   background-color: transparent;
   &__outer {
      flex-grow: 1;
      position: relative; }
   &__inner {
      position: relative;
      width: 600px;
      height: 100%;
      background-color: #FEE9E8; }
   &_active {
      transform: translateX(0); }
   &__close {
      position: absolute;
      top: 50px;
      right: 50%;
      transform: translateX(50%);
      display: none;
      justify-content: center;
      align-items: center; }
   &__close-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 126px;
      height: 126px;
      background: #000000;
      border-radius: 50%;
      opacity: 1;
      color: #ffffff;
      font-size: 12px;
      text-transform: uppercase; } }
.popup-form {
   overflow: hidden;
   box-sizing: border-box;
   .popup__inner {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      box-sizing: border-box;
      padding-top: 20vh;
      overflow-y: auto; } }
.popup-nails .popup__inner {
   background-color: #E7D8E5; }
.popup-skin .popup__inner {
   background-color: #FEE9E8; }
.popup-eyebrows .popup__inner {
   background-color: #DAF4FA; }


/* Magnific Popup settings */
.custom-mfp {
   .mfp-container {
      padding: 0; } }

/* OVERLAY */
/* overlay at start */
.custom-mfp.mfp-bg {
  opacity: 0;
  transition: all 0.15s ease-out; }

/* overlay animate in */
.custom-mfp.mfp-bg.mfp-ready {
  opacity: 0.8; }

/* overlay animate out */
.custom-mfp.mfp-bg.mfp-removing {
  opacity: 0; }

/* CONTENT */
/* content at start */
.custom-mfp.mfp-wrap .mfp-content {
  opacity: 0;
  transform: translateX(600px);
  transition: all 0.3s ease-out; }

/* content animate it */
.custom-mfp.mfp-wrap.mfp-ready .mfp-content {
  transform: translateX(0px);
  opacity: 1; }

/* content animate out */
.custom-mfp.mfp-wrap.mfp-removing .mfp-content {
  transform: translateX(600px);
  opacity: 0; }

@media ( max-width: 1440px ) {
   .popup {
      &__close {
         display: flex; }
      &__svg {
         display: none; } } }
@media ( max-width: 1200px ) {
   .full-price {
      &__icon {
         right: 250px; }
      &__block-select {
         & > .select2-container {
            left: -25px; } } } }
@media ( max-width: 970px ) {
   .popup {
      &__outer {
         position: relative; }

      &__close-wrapper {
         width: 60px;
         height: 60px;
         padding: 0; }
      &__close {
         &-text {
            display: none; } }
      &__svg {
         display: block;
         stroke: #ffffff; } } }

@media ( max-width: 767px ) {
   .popup {
      &__close {
         top: 0;
         width: 60px;
         height: 60px; }
      &__close-wrapper {
         width: 24px;
         height: 24px; }
      &__svg {
         width: 6px;
         height: 6px; }
      &__inner {
         width: 335px; } }
   .price {
      max-width: 335px;
      &__title {
         padding: 27px 19px 40px;
         font-size: 24px;
         line-height: 31px; }
      &__advantage {
         margin-bottom: 50px;
         padding: 0 19px; }
      &__round {
         width: 15.23px;
         height: 15.23px;
         margin-bottom: 21px;
         border: 4px solid #ffffff; }
      &__text {
         font-size: 13px;
         line-height: 168%; }
      &__list {
         margin-bottom: 35px;
         padding: 0 19px; }
      &__fatname, &__number {
         font-size: 13px; } }
   .thanks {
      height: auto;
      padding: 60px 19px 0 19px;
      &__name, &__title {
         font-size: 26px; }
      &__text {
         font-size: 20px; }
      &__light-text {
         font-size: 17px; }
      &__subscription, &__link {
         font-size: 20px; }
      &__block {
         height: 220px;
         margin-right: -19px;
         margin-left: -19px;
         padding: 0 19px; }
      &__icon {
         flex-shrink: 0;
         margin-right: 20px; } }
   .full-price {
      padding: 0 30px 19px 19px;
      &__title {
         font-size: 24px; }
      &__accordeon {
         padding: 20px 0; }
      &__round {
         width: 40px;
         height: 40px; }
      &__item {
         padding: 10px 0; }
      &__icon {
         right: 85px; }
      &__block-select {
         & > .select2-container {
            left: -20px;
            width: 240px !important;
            padding-left: 10px; } } }
   .full-price__block-select > .select2-container--default .select2-selection--single {
      padding-left: 15px;
      width: 240px; }
   .full-price-dropdown > .select2-results > .select2-results__options > .select2-results__option {
      padding-left: 15px !important; }
   .select2-dropdown.custom-dropdown.full-price-dropdown.select2-dropdown--below {
      left: 10px; } }
@media ( max-width: 360px ) {
   .popup {
      &__close {
         right: -30px;
         z-index: 1;
         top: 10px; } }
   .price {
      &__title {
         padding-top: 67px; } }
   .full-price {
      padding-top: 40px;
      &__icon {
         right: 75px; } } }
