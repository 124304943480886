.instagram {
   padding-top: 90px;
   &__btn {
      margin: 0 auto;
      margin-top: 52px;
      margin-bottom: 100px; }
   &__subtitle {
      max-width: 700px;
      margin-bottom: 55px; }
   &__link {
      font-weight: 600;
      color: #6C8FEB; }
   &__br {
      display: none; }
   .slider__progress {
      margin-top: 82px; } }
.eui-widget-title.eapps-instagram-feed-title {
   display: none !important; }
@media ( max-width: 1439px ) {
   .instagram {
      &__btn {
         margin-top: 68px;
         margin-bottom: 121px; }
      &__subtitle {
         margin-bottom: 64px;
         font-size: 20px; } } }

@media ( max-width: 970px ) {
   .instagram {
      padding-top: 71px;
      &__btn {
         margin-top: 65px;
         margin-bottom: 139px; }
      &__subtitle {
         margin-bottom: 50px; } } }

@media ( max-width: 767px ) {
   .instagram {
      padding-top: 175px;
      &__btn {
         margin-top: 43px;
         margin-bottom: 79px; }
      &__title {
         font-size: 26px; }
      &__subtitle {
         margin-bottom: 44px;
         font-size: 18px; }
      &__br {
         display: block; }
      &__link {
         font-weight: 400; } } }
