.icon {
	display: inline-block;
	width: 1em;
	height: 1em;
	fill: currentColor;
}

.icon-20procent {
	font-size:(60/10)*1rem;
	width:(89/60)*1em;
}
.icon-4-7 {
	font-size:(66/10)*1rem;
	width:(66/66)*1em;
}
.icon-7 {
	font-size:(191/10)*1rem;
	width:(132/191)*1em;
}
.icon-arrow-down {
	font-size:(10/10)*1rem;
	width:(13/10)*1em;
}
.icon-arrow-white {
	font-size:(18/10)*1rem;
	width:(13/18)*1em;
}
.icon-bublik-blue {
	font-size:(11/10)*1rem;
	width:(10/11)*1em;
}
.icon-bublik-pink {
	font-size:(10/10)*1rem;
	width:(10/10)*1em;
}
.icon-bublik-purple {
	font-size:(10/10)*1rem;
	width:(10/10)*1em;
}
.icon-close {
	font-size:(18/10)*1rem;
	width:(18/18)*1em;
}
.icon-connector {
	font-size:(28/10)*1rem;
	width:(50/28)*1em;
}
.icon-error {
	font-size:(8/10)*1rem;
	width:(8/8)*1em;
}
.icon-hand {
	font-size:(448/10)*1rem;
	width:(448/448)*1em;
}
.icon-instagram {
	font-size:(22/10)*1rem;
	width:(21/22)*1em;
}
.icon-location {
	font-size:(43/10)*1rem;
	width:(38/43)*1em;
}
.icon-location-header {
	font-size:(19/10)*1rem;
	width:(16/19)*1em;
}
.icon-metro {
	font-size:(6/10)*1rem;
	width:(9/6)*1em;
}
.icon-ok {
	font-size:(11/10)*1rem;
	width:(11/11)*1em;
}
.icon-parking {
	font-size:(7/10)*1rem;
	width:(6/7)*1em;
}
.icon-quotation {
	font-size:(27/10)*1rem;
	width:(28/27)*1em;
}
.icon-star {
	font-size:(11/10)*1rem;
	width:(12/11)*1em;
}
.icon-star-null {
	font-size:(11/10)*1rem;
	width:(12/11)*1em;
}
.icon-stroke-blue-bottom {
	font-size:(31/10)*1rem;
	width:(33/31)*1em;
}
.icon-stroke-blue-top-big {
	font-size:(58/10)*1rem;
	width:(99/58)*1em;
}
.icon-stroke-blue-top-right {
	font-size:(42/10)*1rem;
	width:(44/42)*1em;
}
.icon-stroke-blue-top-small {
	font-size:(72/10)*1rem;
	width:(63/72)*1em;
}
.icon-stroke-pink {
	font-size:(49/10)*1rem;
	width:(32/49)*1em;
}
.icon-stroke-purple-bottom {
	font-size:(82/10)*1rem;
	width:(79/82)*1em;
}
.icon-stroke-purple-small {
	font-size:(61/10)*1rem;
	width:(54/61)*1em;
}
.icon-stroke-yellow {
	font-size:(33/10)*1rem;
	width:(51/33)*1em;
}
.icon-time {
	font-size:(13/10)*1rem;
	width:(13/13)*1em;
}
.icon-vk {
	font-size:(15/10)*1rem;
	width:(24/15)*1em;
}
