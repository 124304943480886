.btn {
    @include flex-center;
    width: 152px;
    height: 152px;
    border-radius: 50%;
    border: none;
    cursor: pointer;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    background: #000000;
    color: #fff;
    font-weight: bold;
    font-size: 14px;
    line-height: 143%;

    &_service-form, &_form, &_warrantly, &_instagram {
        position: relative;
        &::before {
            position: absolute;
            transition: 0.3s; }
        &::after {
            position: absolute;
            transition: 0.3s; }
        &:hover {
            &::before {
                transition: 0.3s; }
            &::after {
                transition: 0.3s; } } }
    &_service-form {
        &::before {
            content: url("data:image/svg+xml,%3Csvg width='63' height='72' viewBox='0 0 63 72' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M54.6893 18.5107C71.2868 16.2518 55.5163 16.4738 54.3119 16.5796C41.9244 17.5882 23.9735 22.0417 11.2935 38.6511C-10.0847 66.5996 11.4412 57.8358 18.4922 43.5743C25.5432 29.3128 38.0918 20.7696 54.6893 18.5107Z' fill='%236C8FEB'/%3E%3C/svg%3E");
            top: -35px;
            left: -25px; }
        &::after {
            content: url("data:image/svg+xml,%3Csvg width='54' height='61' viewBox='0 0 54 61' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.39582 43.4502C12.7015 41.8273 31.8662 36.874 42.4808 21.7577C58.1837 -0.605055 48.648 31.4953 33.1682 39.8507C24.1232 44.733 11.0723 45.8225 1.36923 45.3267C0.176161 45.2657 0.21331 43.6199 1.39582 43.4502Z' fill='%23D5B5F3'/%3E%3C/svg%3E");
            bottom: -50px;
            right: 0px; }
        &:hover {
            &::before {
                top: -45px;
                left: -35px; }
            &::after {
                bottom: -60px;
                right: -10px; } } }
    &_form {
        &::before {
            content: url("data:image/svg+xml,%3Csvg width='99' height='58' viewBox='0 0 99 58' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M86.2393 3.80931C112.24 2.35759 87.7725 0.754723 85.8929 0.76997C66.5707 0.803788 38.2012 5.48959 16.4998 29.6648C-20.0816 70.3392 14.3604 59.4148 27.0483 38.1833C39.7362 16.9519 60.2384 5.26104 86.2393 3.80931Z' fill='%236C8FEB'/%3E%3C/svg%3E");
            top: -35px;
            left: -25px; }
        &::after {
            content: url("data:image/svg+xml,%3Csvg width='51' height='33' viewBox='0 0 51 33' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.993475 30.2075C12.2991 28.5846 31.4639 23.6314 42.0785 8.51498C57.7813 -13.8477 48.2456 18.2526 32.7659 26.6081C23.7209 31.4903 10.6699 32.5798 0.966891 32.084C-0.226182 32.0231 -0.189034 30.3772 0.993475 30.2075Z' fill='%23FFD76F'/%3E%3C/svg%3E");
            bottom: -30px;
            right: 0px; }
        &:hover {
            &::before {
                top: -45px;
                left: -35px; }
            &::after {
                bottom: -40px;
                right: -10px; } } }
    &_warrantly {
        &::before {
            content: url("data:image/svg+xml,%3Csvg width='32' height='49' viewBox='0 0 32 49' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.831282 47.4993C2.33056 33.025 7.06218 9.86955 25.0793 1.34007C28.4531 -0.201077 31.6104 -0.216368 31.5533 3.87486C31.546 10.9783 23.7805 10.4952 17.4559 16.997C7.98719 26.6752 4.21236 38.8548 2.20643 47.6786C2.13604 48.6087 0.7609 48.4294 0.831282 47.4993Z' fill='%23FEE9E8'/%3E%3C/svg%3E%0A");
            top: -10px;
            left: -20px; }
        &::after {
            content: url("data:image/svg+xml,%3Csvg width='33' height='31' viewBox='0 0 33 31' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M32.3567 0.565692C32.5928 0.207175 32.0565 -0.149242 31.8205 0.209275C27.7433 5.23061 25.6494 15.4358 8.42303 22.6699C-4.97898 28.1937 1.59742 30.8897 4.50446 29.9872C26.8131 23.4252 30.738 3.99974 32.3567 0.565692Z' fill='%236C8FEB'/%3E%3C/svg%3E%0A");
            bottom: -27px;
            right: -10px; }
        &:hover {
            &::before {
                top: -20px;
                left: -30px; }
            &::after {
                bottom: -37px;
                right: -20px; } } }
    &_instagram {
        &::before {
            content: url("data:image/svg+xml,%3Csvg width='44' height='42' viewBox='0 0 44 42' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.70094 41.0886C7.19118 30.5867 16.9302 13.3538 34.2767 7.00765C59.9386 -2.38068 26.4643 -1.4671 14.3913 11.3266C7.33683 18.8021 2.91067 31.1278 0.881312 40.6292C0.631784 41.7975 2.23128 42.187 2.70094 41.0886Z' fill='%236C8FEB'/%3E%3C/svg%3E%0A");
            top: -15px;
            left: -20px; }
        &::after {
            content: url("data:image/svg+xml,%3Csvg width='79' height='82' viewBox='0 0 79 82' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M73.857 11.1511C81.9807 -13.5909 77.2041 10.4597 76.7035 12.2714C71.676 30.9282 59.8159 57.1221 30.8525 71.8365C-17.8958 96.6601 1.56066 66.2128 25.3503 59.4445C49.14 52.6762 65.7333 35.893 73.857 11.1511Z' fill='%23D5B5F3'/%3E%3C/svg%3E");
            bottom: -45px;
            right: -25px; }
        &:hover {
            &::before {
                top: -25px;
                left: -30px; }
            &::after {
                bottom: -55px;
                right: -35px; } } }
    &_hidden {
        display: none;
        width: 126px;
        height: 126px;
        font-size: 12px; } }


@media ( max-width: 1439px ) {
    .btn {
        width: 136px;
        height: 136px;
        font-size: 12px;
        &_hidden-header {
            position: relative;
            //display: flex
            width: 126px;
            height: 126px;
            margin: 13px auto 37px;
            z-index: 3; } } }
@media ( max-width: 970px ) {
    .btn {
        &_hidden-header {
            position: absolute;

            bottom: 0;
            right: 50%;
            transform: translateX(50%);

            z-index: 3;
            margin-bottom: 84px; }
        &_warrantly {
            &::before {
                content: url("data:image/svg+xml,%3Csvg width='33' height='57' viewBox='0 0 33 57' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.20482 51.2526C2.7041 36.7782 7.43572 13.6228 25.4528 5.09337C28.8266 3.55222 31.9839 3.53693 31.9268 7.62816C31.9196 14.7316 24.154 14.2485 17.8294 20.7503C8.36072 30.4285 4.58589 42.608 2.57996 51.4319C2.50958 52.362 1.13443 52.1827 1.20482 51.2526Z' fill='%235380F8'/%3E%3C/svg%3E"); }
            &::after {
                content: url("data:image/svg+xml,%3Csvg width='42' height='31' viewBox='0 0 42 31' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M36.3567 0.566058C36.5928 0.207541 36.0565 -0.148876 35.8205 0.209641C31.7433 5.23098 29.6494 15.4361 12.423 22.6702C-0.978979 28.1941 5.59742 30.8901 8.50446 29.9875C30.8131 23.4256 34.738 4.0001 36.3567 0.566058Z' fill='%23D5B5F3'/%3E%3C/svg%3E"); } } } }
@media ( max-width: 767px ) {
    .btn {
        width: 150px;
        height: 50px;
        border-radius: 35px;
        &_hidden-header {
            position: fixed;
            bottom: 72px;
            margin: 0; }
        &_form {
            &::before {
                content: url("data:image/svg+xml,%3Csvg width='52' height='27' viewBox='0 0 99 58' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M86.2393 3.80931C112.24 2.35759 87.7725 0.754723 85.8929 0.76997C66.5707 0.803788 38.2012 5.48959 16.4998 29.6648C-20.0816 70.3392 14.3604 59.4148 27.0483 38.1833C39.7362 16.9519 60.2384 5.26104 86.2393 3.80931Z' fill='%236C8FEB'/%3E%3C/svg%3E");
                top: -15px; }
            &::after {
                content: url("data:image/svg+xml,%3Csvg width='41' height='23' viewBox='0 0 51 33' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.993475 30.2075C12.2991 28.5846 31.4639 23.6314 42.0785 8.51498C57.7813 -13.8477 48.2456 18.2526 32.7659 26.6081C23.7209 31.4903 10.6699 32.5798 0.966891 32.084C-0.226182 32.0231 -0.189034 30.3772 0.993475 30.2075Z' fill='%23FFD76F'/%3E%3C/svg%3E");
                bottom: -20px;
                right: -20px; }
            &:hover {
                &::before {
                    top: -25px; }
                &::after {
                    bottom: -30px;
                    right: -30px; } } }
        &_warrantly {
            width: 205px;
            &::before {
                content: url("data:image/svg+xml,%3Csvg width='23' height='37' viewBox='0 0 33 57' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.20482 51.2526C2.7041 36.7782 7.43572 13.6228 25.4528 5.09337C28.8266 3.55222 31.9839 3.53693 31.9268 7.62816C31.9196 14.7316 24.154 14.2485 17.8294 20.7503C8.36072 30.4285 4.58589 42.608 2.57996 51.4319C2.50958 52.362 1.13443 52.1827 1.20482 51.2526Z' fill='%235380F8'/%3E%3C/svg%3E");
                top: -20px;
                left: -14px; }
            &::after {
                content: url("data:image/svg+xml,%3Csvg width='35' height='31' viewBox='0 0 42 31' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M36.3567 0.566058C36.5928 0.207541 36.0565 -0.148876 35.8205 0.209641C31.7433 5.23098 29.6494 15.4361 12.423 22.6702C-0.978979 28.1941 5.59742 30.8901 8.50446 29.9875C30.8131 23.4256 34.738 4.0001 36.3567 0.566058Z' fill='%23D5B5F3'/%3E%3C/svg%3E");
                bottom: -22px;
                right: -18px; }
            &:hover {
                &::before {
                    top: -30px;
                    left: -24px; }
                &::after {
                    bottom: -32px;
                    right: -28px; } } }
        &_instagram {
            &::before {
                content: url("data:image/svg+xml,%3Csvg width='34' height='32' viewBox='0 0 44 42' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.70094 41.0886C7.19118 30.5867 16.9302 13.3538 34.2767 7.00765C59.9386 -2.38068 26.4643 -1.4671 14.3913 11.3266C7.33683 18.8021 2.91067 31.1278 0.881312 40.6292C0.631784 41.7975 2.23128 42.187 2.70094 41.0886Z' fill='%236C8FEB'/%3E%3C/svg%3E%0A"); }
            &::after {
                content: url("data:image/svg+xml,%3Csvg width='49' height='52' viewBox='0 0 79 82' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M73.857 11.1511C81.9807 -13.5909 77.2041 10.4597 76.7035 12.2714C71.676 30.9282 59.8159 57.1221 30.8525 71.8365C-17.8958 96.6601 1.56066 66.2128 25.3503 59.4445C49.14 52.6762 65.7333 35.893 73.857 11.1511Z' fill='%23D5B5F3'/%3E%3C/svg%3E");
                bottom: -32px;
                right: -23px; }
            &:hover {
                &::after {
                    bottom: -42px;
                    right: -33px; } } }
        &_service-form {
            &::before {
                content: url("data:image/svg+xml,%3Csvg width='53' height='62' viewBox='0 0 63 72' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M54.6893 18.5107C71.2868 16.2518 55.5163 16.4738 54.3119 16.5796C41.9244 17.5882 23.9735 22.0417 11.2935 38.6511C-10.0847 66.5996 11.4412 57.8358 18.4922 43.5743C25.5432 29.3128 38.0918 20.7696 54.6893 18.5107Z' fill='%236C8FEB'/%3E%3C/svg%3E");
                top: -30px;
                left: -29px; }
            &::after {
                content: url("data:image/svg+xml,%3Csvg width='44' height='51' viewBox='0 0 54 61' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.39582 43.4502C12.7015 41.8273 31.8662 36.874 42.4808 21.7577C58.1837 -0.605055 48.648 31.4953 33.1682 39.8507C24.1232 44.733 11.0723 45.8225 1.36923 45.3267C0.176161 45.2657 0.21331 43.6199 1.39582 43.4502Z' fill='%23D5B5F3'/%3E%3C/svg%3E");
                bottom: -32px;
                right: -21px; }
            &:hover {
                &::before {
                    top: -40px;
                    left: -39px; }
                &::after {
                    bottom: -42px;
                    right: -31px; } } } } }

