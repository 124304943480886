.full-price {
   max-width: 600px;
   //height: 100%
   overflow-y: hidden;
   padding: 0 60px 60px;
   background: #FEE9E8;
   &__title {
      display: block;
      font-family: $secondary-font;
      font-weight: bold;
      font-size: 30px;
      line-height: 39px;
      letter-spacing: 0.02em; }
   &__list {
      display: none;
      &_opened {
         display: block; }
      &_noselect {
         display: block; } }
   &__item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 15px 0;
      border-bottom: 2px solid #FFFFFF; }
   &__btn {
      margin-top: 104px; }
   &__accordeon {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 40px 0;
      border-bottom: 2px solid #FFFFFF;
      transition: 0.3s;
      &_last {
         border-bottom: none; } }
   &__round {
      position: relative;
      @include flex-center;
      width: 60px;
      height: 60px;
      background: #F0B2AF;
      border-radius: 50%;
      &:after,
      &:before {
         content: "";
         width: 14px;
         height: 2px;
         background-color: #ffffff;
         display: block;
         position: absolute;
         top: 50%;
         left: 50%; }
      &:before {
         transform: translate(-50%,-50%) rotate(-90deg);
         transition: 0.5s; }
      &:after {
	      transform: translate(-50%,-50%); }
      &.open:before {
         transform: translate(-50%,-50%); } }
   &__icon {
      position: absolute;
      right: 134px; }
   &__block {}

   &__block-select {
      position: relative;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      cursor: pointer;
      & > .select2-container--default .select2-selection--single {
         background: #FEE9E8;
         &:hover {
            background: #ffffff; } }
      & > .select2-container {
         left: -35px; } } }

.full-price-dropdown > .select2-results > .select2-results__options > .select2-results__option:after {
   display: none; }
.full-price-dropdown > .select2-results > .select2-results__options > .select2-results__option:hover {
   background: none; }
.full-price-dropdown > .select2-results > .select2-results__options > .select2-results__option {
   background: none; }
.full-price-dropdown {
   background: #FEE9E8; }
.select2-dropdown.custom-dropdown.full-price-dropdown.select2-dropdown--below {
   border-bottom: 1px solid #ffffff;
   border-left: 1px solid #ffffff;
   border-right: 1px solid #ffffff;
   // border-top-left-radius: 20px
 }   // border-top-right-radius: 20px
#full-price > .scrollbar-outer > .scroll-element.scroll-y .scroll-element_outer {
   top: 20px;
   bottom: 20px;
   height: calc( 100vh - 40px );
   width: 14px; }
#full-price > .scrollbar-outer > .scroll-element.scroll-y .scroll-bar { //цвет бегунка
   //background: #F0B2AF
   background: linear-gradient(90deg, rgba(254,233,232,1) 33%, rgba(240,178,175,1) 33%, rgba(240,178,175,1) 66%, rgba(254,233,232,1) 66%);
   max-height: 530px;
   width: 14px; }
#full-price > .scrollbar-outer > .scroll-element.scroll-y {  //цвет бордера
   padding-right: 20px;
   background: #FEE9E8; }
#full-price > .scrollbar-outer > .scroll-element.scroll-y .scroll-element_track {    //цвет трека
   margin-left: 5px;
   width: 4px;
   opacity: 0.4;
   background: #F0B2AF; }

.full-price {
   &_active {
      border-bottom: 0;
      padding-bottom: 0 !important; }
   &_bg {
      background: #ffffff; } }
