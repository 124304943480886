.gallery {
   padding-top: 100px;
   &__quote {
      margin-bottom: 100px; } }

@media ( max-width: 1439px ) {
   .gallery {
      padding-top: 94px;
      &__quote {
         margin-bottom: 89px; } } }
@media ( max-width: 970px ) {
   .gallery {
      padding-top: 68px;
      &__quote {
         margin-bottom: 63px; } }
   .slider {
      &__item, &__image {
         width: 300px;
         height: 310px; }
      &__service {
         font-size: 15px; }
      &__progress {
         display: none; } } }
@media ( max-width: 767px ) {
   .gallery {
      padding-top: 46px;
      &__quote {
         margin-bottom: 45px; } }
   .quote {
      &__icon {
         height: 22px;
         width: 22px; }
      &__title {
         font-size: 26px; } } }
