.personal {
   padding: 40px;
   overflow-y: auto;
   background-color: #FEE9E8;
   &__title {
      margin-bottom: 20px;
      font-size: 24px; }
   &__subtitle {
      display: block;
      margin-bottom: 15px;
      font-size: 15px;
      font-weight: 600; }
   &__paragraph {
      display: block;
      margin-bottom: 15px;
      font-size: 15px; } }

#personal > .scrollbar-outer > .scroll-element.scroll-y .scroll-element_outer {
   top: 20px;
   bottom: 20px;
   height: calc( 100vh - 40px );
   width: 14px; }
#personal > .scrollbar-outer > .scroll-element.scroll-y .scroll-bar { //цвет бегунка
   //background: #F0B2AF
   background: linear-gradient(90deg, rgba(254,233,232,1) 33%, rgba(240,178,175,1) 33%, rgba(240,178,175,1) 66%, rgba(254,233,232,1) 66%);
   max-height: 530px;
   width: 14px; }
#personal > .scrollbar-outer > .scroll-element.scroll-y {  //цвет бордера
   padding-right: 20px;
   background: #FEE9E8; }
#personal > .scrollbar-outer > .scroll-element.scroll-y .scroll-element_track {    //цвет трека
   margin-left: 5px;
   width: 4px;
   opacity: 0.4;
   background: #F0B2AF; }
