.quote {
   &__icon {
      display: block;
      margin: 0 auto;
      width: 30px;
      height: 30px;
      fill: #F5AB1B;
      &_reverse {
         transform: scaleX(-1); } }
   &__title {
      display: block;
      margin: 30px auto;
      max-width: 600px;
      font-family: $secondary-font;
      font-size: 48px;
      font-weight: 600;
      text-align: center;
      line-height: 130%;
      letter-spacing: 0.02em; } }
