// ====== FONTS =====
$main-font:                 "Plex Sans", sans-serif;
$secondary-font:                "Plex Serif", serif;
$green:                     #394937;

// ===== COLORS =====
$bg-purple:                 #E7D8E5;
$bg-pink:                   #FEE9E8;
$bg-turquoise:              #DAF4FA;
$bg-gray:                   #E7F1F0;
$bg-orange:                 #F9F0E8;
// ===== BASE =====

$yellow:                    #FFD76F;
$dark-yellow:               #F8B93E;
$dark-purple:               #D5B5F3;
$blue:                      #6C8FEB;
$dark-blue:                 #5380F8;
$dark-pink:                 #ED7373;
