.social-list {
    display: flex;
    &__round {
        @include flex-center;
        width: 70px;
        height: 70px;
        background: #FEE9E8;
        border-radius: 50%;
        &:first-child {
            margin-right: 28px; } }
    &__svg {
        fill: #394937;
        width: 25px;
        height: 48px; } }
