.complex {
   display: none; //ВРЕМЕННО
   &__subtitle {
      margin-top: 32px;
      margin-bottom: 90px; }
   &__list {
      @include row-flex();
      justify-content: space-between; }
   &__item {
      @include col();
      @include size(4); }
   &-item {
      height: 660px; }
   &__hand {
      display: none;
      position: absolute;
      bottom: 20px;
      z-index: 3;
      height: 50px;
      width: 50px;
      fill: white;
      transition: 0.5s;
      animation: move 0.5s linear infinite running alternate; } }


@media ( max-width: 1439px ) {
   .complex {
      &__subtitle {
         margin-top: 50px;
         margin-bottom: 80px;
         font-size: 20px; }
      &-item {
         height: 560px;
         padding: 45px 15px 28px 15px;
         &__type {
            margin-bottom: 17px;
            font-size: 12px; }
         &__title {
            font-size: 40px; }
         &__item {
            font-size: 12px; }
         &__list {
            margin-bottom: 28px; }
         &__discount {
            width: 95px;
            height: 88px; }
         &__number {
            width: 66px;
            height: 45px; } } } }
@media ( max-width: 970px ) {
   .complex {
      &__subtitle {
         margin-top: 40px;
         margin-bottom: 70px; }
      &__list {
         justify-content: flex-start; }
      &__item {
         margin-left: 10px;
         margin-right: 10px; }
      &-item {
         width: 335px;
         height: 560px;
         padding: 45px 30px 28px 30px; } } }
@media ( max-width: 767px ) {
   .complex {
      &__subtitle {
         margin-top: 20px;
         margin-bottom: 50px;
         font-size: 18px; }
      &-item {
         height: 515px;
         max-width: 335px;
         padding: 45px 15px 27px 15px;
         box-sizing: border-box;
         &__type {
            margin-bottom: 19px; }
         &__title {
            font-size: 32px; }
         &__list {
            margin-top: 22px;
            margin-bottom: 19px; }
         &__discount {
            width: 83px;
            height: 76px; }
         &__number {
            width: 58px;
            height: 39px; } } } }
@media ( max-width: 420px ) {
   .complex {
      &__hand {
         display: block;
         left: 260px; } } }
@media ( max-width: 370px ) {
   .complex {
      &-item {
         max-width: 280px; }
      &__hand {
         left: 215px; } } }

@keyframes move {
  0% {
      transform: translateX(0); }
  100% {
      transform: translateX(15px); } }

