.form-section {
   padding-top: 77px;
   &__title {
      max-width: 100%; }
   &__subtitle {
      max-width: 575px;
      margin-bottom: 130px; }
   .form {
      align-items: center; } }
// .select2-container .select2-dropdown .select2-results__option
//    text-transform: initial

@media ( max-width: 1200px ) {
   .form-section {
      padding-top: 80px;
      &__title {
         margin-bottom: 30px;
         font-size: 26px; }
      &__subtitle {
         margin-bottom: 80px;
         font-size: 20px; } }
   #form {
      padding: 60px 79px 91px; }
   .br-form {
      display: none; }
   .form {

      &__time {
         margin-left: 25px;
         font-size: 12px; }

      &__line {
         width: 287px;
         margin-left: auto;
         margin-bottom: 20px; }
      &__icon_arrow {
         right: 21px; }
      &__valid {
         right: 13px; } }
   .input {
      width: 287px;
      height: 50px;
      padding: 0 19px;
      font-size: 10px; }
   .select2-container {
      width: 287px !important;
      font-size: 10px; }
   .select2-container .select2-selection__rendered {
      line-height: 50px !important; }
   .select2-container .select2-selection--single {
      width: 287px;
      height: 50px;
      padding: 0 25px; }
   .scrollbar-outer > .scroll-element.scroll-y {
      padding-right: 18px; }
   .scrollbar-outer > .scroll-content.scroll-scrolly_visible {
      padding-right: 0; }
   .select2-container .select2-dropdown .select2-results__option {
      padding: 14px 25px !important; }
   .select2-container--default .select2-results__options {
      max-height: 300px !important; } }
@media ( max-width: 970px ) {
   .form-section {
      padding-top: 71px;
      &__subtitle {
         margin-bottom: 71px; } }
   #form-popup {
      padding-bottom: 142px; }
   #form {
      padding: 69px 58px 142px; }
   .form {
      flex-wrap: wrap;
      justify-content: center;
      &__title {
         max-width: 90%;
         padding-right: 0;
         margin-bottom: 44px;
         text-align: center; }
      &__body {
         width: 100%; }
      &__line {
         margin-right: auto; }
      &__time {
         text-align: center;
         margin-left: 0; } } }

@media ( max-width: 767px ) {
   .form-section {
      padding-top: 50px;
      &__title {
         margin-bottom: 20px;
         font-size: 24px; }
      &__subtitle {
         margin-bottom: 50px;
         font-size: 18px; } }
   #form-popup {
      padding-bottom: 94px; }

   #form {
      margin-left: -20px;
      margin-right: -20px;
      padding: 55px 20px 94px; }
   .form {
      &__title {
         max-width: 80%;
         margin-bottom: 55px;
         font-size: 25px; } } }

@media ( max-width: 375px ) {
   #form-popup {
      padding-bottom: 85px;
 }      // padding-top: 145px
   .popup-form {
      .form__selects {
         display: none; } } }


